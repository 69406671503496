// reference: https://www.geeksforgeeks.org/how-to-get-history-changes-notification-via-history-pushstate-method/
export default (cb = () => false) => {
  (function (history) {
    let pushState = history.pushState;
    history.pushState = function (state) {
      if (typeof history.onpushstate === 'function') {
        history.onpushstate({
          state,
        });
      }
      // eslint-disable-next-line prefer-rest-params
      return pushState.apply(history, arguments);
    };
  })(window.history);

  window.onpopstate = history.onpushstate = function (e) {
    cb(e);
  };
};
