import { h, render } from 'preact';
import BlogFilterController from './BlogFilterController';
import LoadMoreButton from './LoadMoreButton';
import onHistoryChange from './util-functions/onHistoryChange';
import loadBlogsByCriteriaInDom from './util-functions/loadBlogsByCriteriaForDom';
import baseRequestState from '../../constants/baseRequestState';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { createPortal } from 'preact/compat';
import debounced from '../../functions/debounced.func';
import setUrlParamState from './util-functions/setUrlParamState';
import { isPortableScreen } from '../../functions/screenWidth';

const debounceBuilder = debounced(200);

const InitApp = () => {
  const blogListWrapper = document.querySelector(
    'div[data-id="blog-list-wrapper"]',
  );
  const [requestState, setRequestState] = useState(baseRequestState);
  const [postCount, setPostCount] = useState(
    blogListWrapper ? parseFloat(blogListWrapper.dataset.initialPostCount) : 0,
  );
  const [postTotal, setPostTotal] = useState(
    blogListWrapper ? parseFloat(blogListWrapper.dataset.totalPosts) : 0,
  );

  const urlParams = new URLSearchParams(window.location.search);
  const blogSearchParam = urlParams.get('blog_search');
  const topicsParam = urlParams.get('topics');
  const brandsParam = urlParams.get('brands');
  const yearMonthsParam = urlParams.get('year_months');

  const formatCommaSeperatedStringToArray = (string) => {
    return string ? string.split(',') : [];
  };

  const [filterValues, setFilterValues] = useState({
    blog_search: blogSearchParam ? blogSearchParam : '',
    topics: formatCommaSeperatedStringToArray(topicsParam),
    brands: formatCommaSeperatedStringToArray(brandsParam),
    year_months: formatCommaSeperatedStringToArray(yearMonthsParam),
  });

  const loadMoreContainer = document.querySelector(
    'div[data-id="article-load-more-button"]',
  );

  const updateRequest = (obj = {}) => {
    setRequestState({
      ...baseRequestState,
      ...obj,
    });
  };

  const handleFilterChange = (e) => {
    const {
      target: { value: inputValue, checked, type, name },
    } = e;

    let newParam = inputValue;

    let prevState = history.state ? history.state : {};

    const existValues = formatCommaSeperatedStringToArray(
      prevState ? prevState[name] : '',
    );
    if (type === 'checkbox') {
      const newValues = checked
        ? [...existValues, inputValue]
        : existValues.filter((value) => value !== inputValue);
      newParam = newValues.join(',');
      return debounceBuilder(
        () =>
          setUrlParamState({
            [name]: newParam,
          }),
        300,
      );
    }

    return setUrlParamState({ [name]: newParam });
  };

  const changeToFailedReqState = useCallback(({ post_count, total_posts }) => {
    updateRequest({ failed: true });
    setPostCount(post_count);
    setPostTotal(total_posts);
  }, []);
  const changeToSuccessReqState = useCallback(({ post_count, total_posts }) => {
    updateRequest({ success: true });
    setPostCount(post_count);
    setPostTotal(total_posts);
  }, []);

  const handleHistoryChange = useCallback(
    (e) => {
      updateRequest({ loading: true });
      const { state } = e;
      const newState = state ? state : {};
      const {
        posts_per_page = 5,
        year_months = '',
        topics = '',
        brands = '',
        blog_search = '',
      } = newState;
      loadBlogsByCriteriaInDom(
        {
          posts_per_page,
          year_months,
          topics,
          brands,
          blog_search,
        },
        changeToSuccessReqState,
        changeToFailedReqState,
      );
      setFilterValues((prevState) => ({
        ...prevState,
        blog_search,
        topics: [...formatCommaSeperatedStringToArray(topics)],
        brands: [...formatCommaSeperatedStringToArray(brands)],
        year_months: [...formatCommaSeperatedStringToArray(year_months)],
      }));
    },
    [changeToFailedReqState, changeToSuccessReqState],
  );

  useEffect(() => {
    onHistoryChange(handleHistoryChange);
  }, [handleHistoryChange]);

  const applyFilterOnSingleBlog = () => {
    let current_url = window.location.href;
    if (!current_url.includes('/blog')) {
      let new_url = `${window.location.origin}/blog/${window.location.search}`;
      window.history.replaceState({}, '', new_url);
      if (!isPortableScreen()) {
        setTimeout(function () {
          window.location.reload();
        }, 200);
      }
    }
  };
  
  return (
    <div>
      <BlogFilterController
        filterValues={filterValues}
        onFilterChange={handleFilterChange}
        requestFailed={requestState.failed}
        requestLoading={requestState.loading}
        applyFilterOnSingleBlog={applyFilterOnSingleBlog}
      />
      {loadMoreContainer
        ? createPortal(
            <LoadMoreButton
              postTotal={postTotal}
              postCount={postCount}
              requestFailed={requestState.failed}
              requestLoading={requestState.loading}
            />,
            loadMoreContainer,
          )
        : null}
    </div>
  );
};

const blogFilterAppContainer = document.querySelector(
  'div[data-id="blog-filter-app"]',
);

if (blogFilterAppContainer) {
  render(<InitApp />, blogFilterAppContainer);
}
