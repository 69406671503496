import { h } from 'preact';
import setUrlParamState from './util-functions/setUrlParamState';

const LoadMoreButton = ({
  requestLoading,
  requestFailed,
  postCount,
  postTotal,
}) => {
  const handleLoadMoreBlogsClick = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const currentPostsPerPage = urlParams.get('posts_per_page');
    const newPostsPerPage = currentPostsPerPage
      ? parseFloat(currentPostsPerPage) + 5
      : 10;

    setUrlParamState({
      posts_per_page: newPostsPerPage,
    });
  };

  const getButtonText = () => {
    switch (true) {
      case requestLoading:
        return 'Fetching';
      case requestFailed:
        return 'No results';
      default:
        return 'Load More';
    }
  };

  return (
    <button
      className={`block-card__link-wrapper__link ${
        postCount < postTotal ? '' : 'hidden'
      }`}
      type="button"
      onClick={handleLoadMoreBlogsClick}
      disabled={postCount === 0}>
      {getButtonText()}
    </button>
  );
};

export default LoadMoreButton;

LoadMoreButton.defaultProps = {
  requestLoading: false,
  requestSuccess: false,
  requestFailed: false,
  postCount: 0,
};
