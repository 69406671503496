import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { toTitleCase } from '@bonlineza/b-lib/functions';
import { hasValue } from '@bonlineza/b-lib/build/functions';
import Accordion from '@bonlineza/luxity-lib/Accordion';
import { isPortableScreen } from '../../functions/screenWidth';

const BlogFilterController = ({
  filterValues,
  onFilterChange,
  applyFilterOnSingleBlog,
}) => {
  const {
    blog_search: existingSearch = '',
    topics: existingTopics = [],
    brands: existingBrands = [],
    year_months: existingYearMonths = [],
  } = filterValues;
  /*
   * blog_selection_filter_data is a localized variable
   * Refer to custom_manage_styles_and_scripts in functions.php
   * */
  const {
    blog_categories_selection: topics = [],
    year_months_selection: yearMonthSelectionObj = {},
    brands_selection = [],
  } = blog_selection_filter_data;

  const [brandsSearchFilterInputValue, setBrandsSearchFilterInput] = useState();
  const [isTopicsOpen, setIsTopicOpen] = useState(!isPortableScreen());
  const [isMonthlyOpen, setIsMonthlyOpen] = useState(!isPortableScreen());
  const [isBrandsOpen, setIsBrandsOpen] = useState(!isPortableScreen());
  const [isMobile, setIsMobile] = useState(isPortableScreen());
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [seletectedFilter, setSelectedFilter] = useState(0);

  //choose the screen size
  const handleResize = () => {
    if (isPortableScreen()) {
      setIsMobile((prevState) => {
        if (prevState === false) {
          setIsTopicOpen(false);
          setIsMonthlyOpen(false);
          setIsBrandsOpen(false);
        }
        return true;
      });
    } else {
      setIsMobile((prevState) => {
        if (prevState === true) {
          setIsTopicOpen(true);
          setIsMonthlyOpen(true);
          setIsBrandsOpen(true);
        }
        return false;
      });
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    let filtered_count = 0;
    if (filterValues.blog_search) {
      filtered_count += 1;
    }
    filtered_count += filterValues?.topics?.length;
    filtered_count += filterValues?.brands?.length;
    filtered_count += filterValues?.year_months?.length;
    setSelectedFilter(filtered_count);
  }, [filterValues]);

  const shapedDataForYearMonthSelection = formatYearMonthData(
    yearMonthSelectionObj,
  );

  const toggleBlogCategory = (category) => {
    switch (category) {
      case 'topics':
        setIsTopicOpen((prevState) => {
          setIsCategoryOpen(!prevState);
          return !prevState;
        });
        break;
      case 'monthly':
        setIsMonthlyOpen((prevState) => {
          setIsCategoryOpen(!prevState);
          return !prevState;
        });
        break;
      case 'brands':
        setIsBrandsOpen((prevState) => {
          setIsCategoryOpen(!prevState);
          return !prevState;
        });
        break;
      default:
        break;
    }
  };

  const handleBrandsOptsFiltererInputChange = (e) => {
    const {
      target: { value },
    } = e;
    setBrandsSearchFilterInput(value);
  };
  const onInputChange = (e) => {
    onFilterChange(e);
    setTimeout(() => {
      applyFilterOnSingleBlog();
    }, 100);
  };

  const onSearchBlog = (e) => {
    if (isMobile || e.key === 'Enter') {
      onInputChange(e);
    }
  };

  const renderBrandFilterOptions = () => {
    let filterOptions = [...brands_selection];
    if (hasValue(brandsSearchFilterInputValue)) {
      filterOptions = filterOptions.filter(({ name }) => {
        return name
          .toLowerCase()
          .includes(brandsSearchFilterInputValue.toLowerCase());
      });
    }

    return filterOptions.map(({ term_id, name, count = 0, slug = '' }) => {
      if (parseFloat(count) > 0) {
        return (
          <label htmlFor={term_id}>
            <input
              type="checkbox"
              id={term_id}
              name="brands"
              value={slug}
              checked={existingBrands.some(
                (existingBrand = '') => existingBrand === slug,
              )}
              onChange={onInputChange}
            />
            <div className="filter-option-row">
              {replaceAmpCodeWithSymbol(name)}
              <div className="available-count">{`(${count})`}</div>
            </div>
          </label>
        );
      }
      return null;
    });
  };

  const minusIcon = () => {
    if (isMobile && isCategoryOpen) {
      return;
    }
    return (
      <img
        className="blog-icon"
        src={`${WP_SITE_URL}/wp-content/themes/bonline/images/minus.svg`}
      />
    );
  };

  const plusIcon = () => {
    if (isMobile && isCategoryOpen) {
      return;
    }
    return (
      <img
        className="blog-icon"
        src={`${WP_SITE_URL}/wp-content/themes/bonline/images/plus.svg`}
      />
    );
  };

  const searchIcon = () => {
    return (
      <img
        className="blog-icon search-icon"
        src={`${WP_SITE_URL}/wp-content/themes/bonline/images/search.svg`}
      />
    );
  };

  const backwardSlashIcon = () => {
    return (
      <img
        className="blog-icon backwards-slash"
        src={`${WP_SITE_URL}/wp-content/themes/bonline/images/backwards-slash.svg`}
      />
    );
  };

  const backArrowIcon = () => {
    return (
      <img
        className="blog-icon backwards-slash"
        src={`${WP_SITE_URL}/wp-content/themes/bonline/images/back-arrow.svg`}
      />
    );
  };

  const applyHiddenClassOnCategoryClick = (category) => {
    if (isMobile) {
      switch (category) {
        case 'topics':
          return isMonthlyOpen || isBrandsOpen ? 'hidden' : '';
        case 'monthly':
          return isTopicsOpen || isBrandsOpen ? 'hidden' : '';
        case 'brands':
          return isTopicsOpen || isMonthlyOpen ? 'hidden' : '';
        default:
          return '';
      }
    }
    return '';
  };

  const showFilterBlogSearch = () => {
    if (isMobile && (isMonthlyOpen || isTopicsOpen || isBrandsOpen)) {
      return false;
    }
    return true;
  };

  const TitleComponent = (title) => {
    return (
      <div className="blog-filter-accordion-title">
        {isMobile && isCategoryOpen ? backArrowIcon() : ''}
        <span className="blog-accordion-title">{title}</span>
      </div>
    );
  };

  const replaceAmpCodeWithSymbol = (string) => {
    return string.replace('&amp;', '&');
  };

  const showNumberSelectedFilters = () => {
    return (
      <div>{`${seletectedFilter === 0 ? 'No' : seletectedFilter} Filter${
        seletectedFilter > 1 ? 's' : ''
      } Selected`}</div>
    );
  };

  const closeBlogFilter = () => {
    let blogOverlay = document.getElementsByClassName('blog-filter-overlay');
    Array.from(blogOverlay).forEach((el) => {
      el.click();
    });
  };

  const clearBlogFilter = () => {
    window.location.assign(`/blog`);
  };

  return (
    <div className="filter-wrapper">
      <div className="filter-close-menu">
        <button className="filter-close-menu__button">
          <img
            onClick={closeBlogFilter}
            src={`${WP_SITE_URL}/wp-content/themes/bonline/images/close.svg`}
            alt="Filter Close"
            className="filter-close-menu__icon"
          />
        </button>
      </div>
      <div className={`${showFilterBlogSearch() ? '' : 'hidden'}`}>
        <div className="filter-blog-title">
          <div>{isMobile && backwardSlashIcon()}</div>
          <div className="title">
            <h3 className="blog-filter-title">Filter Blogs/</h3>
          </div>
        </div>
        <div className="filter-search">
          {searchIcon()}
          <input
            className="blog-search-input"
            id="blog_search"
            name="blog_search"
            placeholder="Search"
            onKeyUp={onSearchBlog}
            value={existingSearch}
          />
        </div>
        <div className="seleted-filter-count">
          {showNumberSelectedFilters()}
          <div>
            <a className="clear-all" href={`${WP_SITE_URL}`}>
              Clear All
            </a>
          </div>
        </div>
      </div>
      <div>
        <div className={`${applyHiddenClassOnCategoryClick('topics')}`}>
          <Accordion
            identifier="topics"
            containerClass={`${
              isTopicsOpen ? '' : 'accordion-closed--bottom-border'
            } blog-filter-accordion-container`}
            wrapperClass={`blog-filter-accordion ${
              isTopicsOpen ? 'hide-bottom-border' : ''
            }`}
            onToggle={() => toggleBlogCategory('topics')}
            closeIndicator={() => minusIcon()}
            openIndicator={() => plusIcon()}
            title={TitleComponent('Topics')}
            defaultHeight={isMobile ? '0' : 'auto'}>
            {// eslint-disable-next-line no-unused-vars
            (accordionClose, accordionOpen, _accordionToggle) => {
              if (!isTopicsOpen) {
                accordionClose();
              } else {
                accordionOpen();
              }
              return topics.map(
                (topic) =>
                  topic.count > 0 && (
                    <label htmlFor={topic.term_id}>
                      <input
                        id={topic.term_id}
                        type="checkbox"
                        name="topics"
                        value={topic.slug}
                        onChange={onInputChange}
                        checked={existingTopics.some(
                          (existingTopic = '') => existingTopic === topic.slug,
                        )}
                      />
                      <div className="filter-option-row">
                        {replaceAmpCodeWithSymbol(topic.name)}
                        <div className="available-count">{`(${topic.count})`}</div>
                      </div>
                    </label>
                  ),
              );
            }}
          </Accordion>
        </div>

        <div className={`${applyHiddenClassOnCategoryClick('brands')}`}>
          <Accordion
            identifier="brands"
            containerClass={`${
              isBrandsOpen ? '' : 'accordion-closed--bottom-border'
            } blog-filter-accordion-container`}
            wrapperClass={`blog-filter-accordion ${
              isBrandsOpen ? 'hide-bottom-border' : ''
            }`}
            onToggle={() => toggleBlogCategory('brands')}
            closeIndicator={() => minusIcon()}
            openIndicator={() => plusIcon()}
            title={TitleComponent('Brands')}
            defaultHeight={isMobile ? '0' : 'auto'}>
            {// eslint-disable-next-line no-unused-vars
            (accordionClose, accordionOpen, _accordionToggle) => {
              if (!isBrandsOpen) {
                accordionClose();
              } else {
                accordionOpen();
              }
              return (
                <div className="scroll-container">
                  <div className="brand-filter-search">
                    {searchIcon()}
                    <input
                      className="blog-search-input"
                      name="brands_option_selection_filterer_input"
                      id="brands_option_selection_filterer_input"
                      placeholder="Search"
                      onChange={handleBrandsOptsFiltererInputChange}
                    />
                  </div>
                  <div>{renderBrandFilterOptions()}</div>
                </div>
              );
            }}
          </Accordion>
        </div>

        <div className={`${applyHiddenClassOnCategoryClick('monthly')}`}>
          <Accordion
            identifier="monthly"
            onToggle={() => toggleBlogCategory('monthly')}
            containerClass={`${
              isMonthlyOpen ? '' : 'accordion-closed--bottom-border'
            } blog-filter-accordion-container`}
            wrapperClass={`blog-filter-accordion ${
              isMonthlyOpen ? 'hide-bottom-border' : ''
            }`}
            closeIndicator={() => minusIcon()}
            openIndicator={() => plusIcon()}
            title={TitleComponent('Monthly')}
            defaultHeight={isMobile ? '0' : 'auto'}>
            {// eslint-disable-next-line no-unused-vars
            (accordionClose, accordionOpen, _accordionToggle) => {
              if (!isMonthlyOpen) {
                accordionClose();
              } else {
                accordionOpen();
              }
              return (
                <div className="scroll-container">
                  {shapedDataForYearMonthSelection.map(
                    ({ yearNumber = '', months = [] }) => (
                      <div className="year-section">
                        <div className="year-number">{`- ${yearNumber}`}</div>
                        {months.map(({ monthName = '', articleCount = 0 }) => {
                          if (parseFloat(articleCount) > 0) {
                            return (
                              <label htmlFor={`${yearNumber}-${monthName}`}>
                                <input
                                  id={`${yearNumber}-${monthName}`}
                                  type="checkbox"
                                  name="year_months"
                                  value={`${yearNumber}-${monthName}`}
                                  checked={existingYearMonths.some(
                                    (existingYearMonth = '') =>
                                      existingYearMonth ===
                                      `${yearNumber}-${monthName}`,
                                  )}
                                  onChange={onInputChange}
                                />
                                <div className="filter-option-row">
                                  {toTitleCase(monthName)}
                                  <div className="available-count">{`(${articleCount})`}</div>
                                </div>
                              </label>
                            );
                          }
                          return null;
                        })}
                      </div>
                    ),
                  )}
                </div>
              );
            }}
          </Accordion>
        </div>
      </div>
      <div className="filter-buttons-section">
        <div className="selected-count__mobile">
          {showNumberSelectedFilters()}
        </div>
        {!isCategoryOpen && (
          <div className="clear-filter" onClick={clearBlogFilter}>
            <span className="clear-filter__content">Clear All</span>
          </div>
        )}
        {isMobile && (
          <div
            className="apply-filter"
            onClick={() => {
              window.location.reload();
            }}>
            <span className="apply-filter__content">
              {isCategoryOpen ? 'Select' : 'Apply Filters'}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogFilterController;

BlogFilterController.defaultProps = {
  requestLoading: false,
  requestSuccess: false,
  requestFailed: false,
  filterValues: {},
};

function formatYearMonthData(data = {}) {
  let result_arr = [];
  for (const key in data) {
    for (const year in data[key]) {
      let result_months_arr = [];
      let result_obj = {};
      for (const month in data[key][year]) {
        result_months_arr.push({
          monthName: month,
          articleCount: data[key][year][month],
        });
      }
      result_obj = {
        yearNumber: year,
        months: result_months_arr,
      };
      result_arr.push(result_obj);
    }
  }
  return result_arr;
}
