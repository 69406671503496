/*
 * TODO: replace with b-lib function once exportable functions are treeshakeable
 *  from b-lib
 * */

export default function debounced(delay) {
  let timer = null;
  return function (fn, ...args) {
    const context = this;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}
