const setUrlParamState = (paramData = {}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const oldState = history.state;

  Object.keys(paramData).forEach((key) => {
    urlParams.set(key, paramData[key]);
  });

  history.pushState(
    {
      ...oldState,
      ...paramData,
    },
    null,
    `?${urlParams}`,
  );
};

export default setUrlParamState;
