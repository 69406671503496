import api from '../../../constants/api';

export default (
  {
    posts_per_page = 5,
    year_months = '',
    topics = '',
    brands = '',
    blog_search = '',
  },
  successCb = () => false,
  failedCb = () => false,
) => {
  const payload = {
    posts_per_page,
    year_months,
    topics,
    brands,
    blog_search,
  };
  const options = {
    method: 'POST',
    body: JSON.stringify(payload),
  };

  fetch(api.FILTER_BLOGS, options)
    .then(status)
    .then(json)
    .then(
      ({
        response: {
          data: { html, post_count, total_posts },
        },
      }) => {
        successCb({ post_count, total_posts });
        setBloggerInnerHtml(html);
      },
    )
    .catch(() => {
      let html = '<h3>Could not filter blogs</h3>';
      failedCb({ post_count: 0, total_posts: 0 });
      setBloggerInnerHtml(html);
    });
};

function json(response) {
  return response.json();
}

function status(res) {
  if (res.status === 200) {
    return res;
  }
  return res.json().then((json) => {
    throw json;
  });
}

function setBloggerInnerHtml(html = '') {
  const blogListWrapper = document.querySelector(
    'div[data-id="blog-list-wrapper"]',
  );
  if (blogListWrapper) {
    blogListWrapper.innerHTML = html;
  }
}
